<template>
  <div class="game-container">
    <SpeakingSentence :buttonList="buttonList" :gameList="gameList" @initGame="initGame"  @showSentence="showSentence"/>
  </div>
</template>

<script>
import SpeakingSentence from "@/components/Course/CoursePage/SpeakingSentence";
export default {
  data() {
    return {
      gameList:[
        {
          bgImg:require(`@/assets/img/16-GAMES/G59-click-speaking/robot-dream-1.svg`),
          pinyin:"Wǒ yǒu yí ge bàba.",
          hanzi:"我有一个爸爸。",
          isShowSentence:false,
        },
        {
          bgImg:require(`@/assets/img/16-GAMES/G59-click-speaking/robot-dream-2.svg`),
          pinyin:"Wǒ yǒu sān ge māma.",
          hanzi:"我有三个妈妈。",
          isShowSentence:false,
        },
        {
          bgImg:require(`@/assets/img/16-GAMES/G59-click-speaking/robot-dream-3.svg`),
          pinyin:"Wǒ yǒu wǔ ge mèimei.",
          hanzi:"我有五个妹妹。",
          isShowSentence:false,
        },
      ],
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G59-click-speaking/menu-robot-1.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G59-click-speaking/menu-robot-2.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G59-click-speaking/menu-robot-3.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
      ],
    };
  },
  components: {
    SpeakingSentence,
  },
  methods:{
    initGame() {
      this.gameList.forEach((item)=>{
        item.isShowSentence = false
      })
    },
        showSentence(index) {
     this.gameList[index -1].isShowSentence = true
    },
  }
}
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>