<template>
  <div class="click-reveal-game-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="game-content">
      <div class="backGroundImg-area">
        <img :src="gameInfo.bgImg" alt="" class="bg-img" />
        <div
          class="img-item"
          v-for="(item, index) in optionsImgList"
          :key="index"
        >
          <img :src="item.bgImg" alt="" v-if="index === clickIndex" />
        </div>
      </div>
      <div class="title-area">
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ gameInfo.titlePinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">
            {{ gameInfo.titleHanzi }}
          </div>
        </div>
      </div>

      <div class="click-area">
        <template v-for="(item, index) in optionsImgList">
          <div
            class="click-item"
            :key="index"
            @click="handleClickPeople(item.index)"
            :class="'area-' + index"
            v-if="clickdeIndexArr.indexOf(index) === -1"
          ></div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
export default {
  name: "ClickRevealGame",
  props: {
    optionsImgList: {
      type: Array,
      require: true,
      default: () => [],
    },
    gameInfo: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  components: {
    PageButton,
  },
  watch: {
    clickSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20044,
          data: { value },
          text: "ClickRevealGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("ClickThePeople", ({ index }) => {
      this.handleClickPeople(index, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("ClickThePeople");
  },
  data() {
    return {
      clickIndex: null,
      isLastStep: false,
      clickSocketInfo: {},
      clickdeIndexArr: [],

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
    };
  },
  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    handleClickPeople(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      this.clickIndex = index;
      this.clickdeIndexArr.push(index);
      if (this.clickdeIndexArr.length === this.optionsImgList.length) {
        startConfetti();
        playCorrectSound();
        this.isLastStep = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.click-reveal-game-container {
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    .backGroundImg-area {
      width: 100%;
      height: 100%;
      position: relative;
      .bg-img {
        border-radius: 48px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .img-item {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .title-area,
    .click-area {
      position: absolute;
      top: 0;
      left: 0;
    }
    .title-area {
      top: 10%;
      width: 18%;
      height: 17%;
      background: #cd4c3f;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      color: #fff;
      .title {
        margin-left: 5%;
        .title-pinyin {
          margin-left: 10px;
        }
      }
    }
    .click-area {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      .click-item {
        position: absolute;
        cursor: pointer;
      }
      .area-0 {
        width: 15%;
        height: 53%;
        top: 9%;
        left: 36%;
      }

      .area-1 {
        width: 12%;
        height: 79%;
        top: 13%;
        left: 53%;
        border-radius: 40px;
      }
      .area-2 {
        width: 14%;
        height: 51%;
        top: 43%;
        left: 23%;
        border-radius: 40px;
      }
      .area-3 {
        width: 13%;
        height: 42%;
        top: 51%;
        left: 64%;
        border-radius: 40px;
      }
    }
  }
}
</style>