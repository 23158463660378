<template>
  <div class="game-container">
    <SeesawGame
      :chooseHanziList="chooseHanziList"
      :gameInfo="gameInfo"
      :starNum="3"
      :stepsImgList="stepsImgList"
      :answerIdList="[15, 46, 27]"
    />
  </div>
</template>

<script>
import SeesawGame from "@/components/Course/GamePage/SeesawGame";
export default {
  data() {
    return {
      gameInfo: {
        seasawBottomImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/base-ge.svg`),
        baseImg: require(`@/assets/img/16-GAMES/G34-scales/base-scales.svg`),
      },
      stepsImgList: [
        {
          id: 1, // yi
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-yi.svg`),
        },
        {
          id: 2,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-san.svg`),
        },
        {
          id: 3,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-si.svg`),
        },
        {
          id: 4,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-wu.svg`),
        },
        {
          id: 5, // gege
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-gege.svg`),
        },
        {
          id: 6,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-bb.svg`),
        },
        {
          id: 7,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-mama.svg`),
        },
        {
          id: 12,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-yi-san.svg`),
        },
        {
          id: 15,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-yi-gege.svg`),
        },
        {
          id: 21,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-san-yi.svg`),
        },
        {
          id: 25,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-san-gege.svg`),
        },
        {
          id: 51,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-gege-yi.svg`),
        },
        {
          id: 52,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-gege-san.svg`),
        },


        {
          id: 34,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-si-wu.svg`),
        },
        {
          id: 36,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-si-bb.svg`),
        },
        {
          id: 43,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-wu-si.svg`),
        },
        {
          id: 46,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-wu-bb.svg`),
        },
        {
          id: 64,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-bb-si.svg`),
        },
        {
          id: 63,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-bb-wu.svg`),
        },


                {
          id: 24,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-san-wu.svg`),
        },
        {
          id: 27,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-san-mama.svg`),
        },
        {
          id: 42,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-wu-san.svg`),
        },
        {
          id: 47,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-wu-mama.svg`),
        },
        {
          id: 72,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-mama-san.svg`),
        },
        {
          id: 74,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-mama-wu.svg`),
        },
      ],
      chooseHanziList: [
        {
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/lesson-11-story-1.svg`),
          wordsPinyin: "yí ge gēge",
          wordsHanzi: "一个哥哥",
          hanziChooseList: [
            {
              pinyin: "yī",
              hanzi: "一",
              id: 1,
            },
            {
              pinyin: "gēge",
              hanzi: "哥哥",
              id: 5,
            },

            {
              pinyin: "sān",
              hanzi: "三",
              id: 2,
            },
          ],
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/lesson-11-story-2.svg`),
          wordsPinyin: "wǔ ge bb",
          wordsHanzi: "五个BB",
          hanziChooseList: [
            {
              pinyin: "sì",
              hanzi: "四",
              id: 3,
            },
            {
              pinyin: "bb",
              hanzi: "BB",
              id: 6,
            },
            {
              pinyin: "wǔ",
              hanzi: "五",
              id: 4,
            },
          ],
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/lesson-11-story-3.svg`),
          wordsPinyin: "sān ge māma",
          wordsHanzi: "三个妈妈",

          hanziChooseList: [
            {
              pinyin: "wǔ",
              hanzi: "五",
              id: 4,
            },
            {
              pinyin: "māma",
              hanzi: "妈妈",
              id: 7,
            },
            {
              pinyin: "sān ",
              hanzi: "三",
              id: 2,
            },
          ],
        },
      ],
    };
  },
  components: {
    SeesawGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>