<template>
  <div class="rank-map-page-one">
    <RankMapPage
      :lesson="lessonNum"
      :position="positionNum"
      :chapterNum="chapter"
      :chapterString="'2'"
    />
  </div>
</template>

<script>
import RankMapPage from "@/components/Course/CoursePage/RankMapPage";
export default {
  data() {
    return {
      lessonNum: 3, // course lesson
      positionNum: 0, // 0: start  1: end
      chapter: 2,
    };
  },
  components: {
    RankMapPage,
  },
};
</script>

<style lang="scss" scoped>
.rank-map-page-one {
  width: 100%;
  height: 100%;
}
</style>