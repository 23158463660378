<template>
  <div class="game-container">
    <ChooseWordsByPicGame
      :chooseHanziList="chooseHanziList"
      :optionsList="optionsList"
      :lessonNum="11"
      :isNeedTips="true"
      :gameInfo="gameInfo"
      :hasMenu="false"
    />
  </div>
</template>

<script>
import ChooseWordsByPicGame from "@/components/Course/GamePage/ChooseWordsByPicGame";
export default {
  data() {
    return {
      gameInfo: {
        titlePinyin: "Xiǎng yi xiǎng: Dòudou xiǎng shuō shénme?",
        titleHanzi: "想一想：豆豆想说什么？",
      },
      chooseHanziList: [
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-story-words/lesson-11-corgie-family.svg`),
          wordsPinyin: "Wǒ yǒu wǔ ge mèimei.",
          wordsHanzi: "我有五个妹妹。",
          hanziRightList: [
            {
              pinyin: "yǒu",
              hanzi: "有",
              id: 3,
            },
            {
              pinyin: "wǔ",
              hanzi: "五",
              id: 2,
            },
            {
              pinyin: "gè",
              hanzi: "个",
              id: 4,
            },
            {
              pinyin: "mèimei",
              hanzi: "妹妹",
              id: 1,
            },
          ],
        },
      ],
      optionsList: [
        {
          pinyin: "mèimei",
          hanzi: "妹妹",
          id: 1,
        },
        {
          pinyin: "wǔ",
          hanzi: "五",
          id: 2,
        },
        {
          pinyin: "yǒu",
          hanzi: "有",
          id: 3,
        },
        {
          pinyin: "gè",
          hanzi: "个",
          id: 4,
        },
      ],
    };
  },
  components: {
    ChooseWordsByPicGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
