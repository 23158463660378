<template>
  <div class="game-container">
    <ClickRevealGame :optionsImgList="optionsImgList" :gameInfo="gameInfo" />
  </div>
</template>

<script>
import ClickRevealGame from "@/components/Course/GamePage/ClickRevealGame";
export default {
  data() {
    return {
      gameInfo: {
        bgImg: require("@/assets/img/16-GAMES/G28-click-reveal/background-family.svg"),
        titleHanzi: "他是……",
        titlePinyin: "Tā shì …",
      },
      optionsImgList: [
        {
          bgImg: require("@/assets/img/16-GAMES/G28-click-reveal/dad-glowing.svg"),
          index: 0,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G28-click-reveal/mom-glowing.png"),
          index: 1,
        },
        {
           bgImg: require("@/assets/img/16-GAMES/G28-click-reveal/son-glowing.svg"),
          index: 2,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G28-click-reveal/daughter-glowing.svg"),
          index: 3,
        },
      ],
    };
  },
  components: {
    ClickRevealGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











