<template>
  <div class="game-container">
    <FamilyTreeGame
      :gameInfo="gameInfo"
      :optionsList="optionsList"
      :sentenceList="sentenceList"
    />
  </div>
</template>

<script>
import FamilyTreeGame from "@/components/Course/GamePage/FamilyTreeGame";
export default {
  data() {
    return {
      gameInfo: {
        bgImg: require("@/assets/img/16-GAMES/G57-tree/lesson-11/tree-background.svg"),
        titleHanzi: " ……有……",
        titlePinyin: "yǒu",
        selfImg: require("@/assets/img/16-GAMES/G57-tree/lesson-11/dongdong.svg"),
        selfPinyin: "Dōngdōng",
        selfHanzi: "冬冬",
      },
      optionsList: [
        {
          pinyin: "mèimei",
          hanzi: "妹妹",
          id: 3,
          bgImg: require("@/assets/img/16-GAMES/G57-tree/lesson-11/meimei.svg"),
        },
        {
          pinyin: "bàba",
          hanzi: "爸爸",
          id: 1,
          bgImg: require("@/assets/img/16-GAMES/G57-tree/lesson-11/baba.svg"),
        },

        {
          pinyin: "māma",
          hanzi: "妈妈",
          id: 2,
          bgImg: require("@/assets/img/16-GAMES/G57-tree/lesson-11/mama.svg"),
        },
      ],
      sentenceList: [
        {
          id: 1,
          bgImg: require("@/assets/img/16-GAMES/G57-tree/lesson-11/baba.svg"),
          hanzi: "冬冬有一个爸爸。",
          pinyin: "Dōngdōng yǒu yí ge bàba.",
        },
        {
          id: 2,
          bgImg: require("@/assets/img/16-GAMES/G57-tree/lesson-11/mama.svg"),
          hanzi: "冬冬有一个妈妈。",
          pinyin: "Dōngdōng yǒu yí ge māma.",
        },
        {
          id: 3,
          bgImg: require("@/assets/img/16-GAMES/G57-tree/lesson-11/meimei.svg"),
          hanzi: "冬冬有一个妹妹。",
          pinyin: "Dōngdōng yǒu yí ge mèimei.",
        },
      ],
    };
  },
  components: {
    FamilyTreeGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











